
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "motor-policy-period-breakdown-component",
  components: { ErrorMessage, Field, Form },
  props: {
    displayBreakdown: Boolean,
    vehicles: Array,
    publicId: String,
    redirect: String,
    role: String,
  },
  data() {
    return {
      certificate: "",
      folioItem: "",
      folioIndex: "",
      submitRoute: "",
    };
  },
  setup(props) {
    // const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const documentUploadValidator = Yup.object().shape({
      certificate: Yup.mixed()
        .required()
        .label("Policy certificate"),
    });

    const submitForm = (values) => {
      const submitButton1 = document.getElementById(
        values["folioItem"]
      ) as HTMLElement;

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1?.removeAttribute("data-kt-indicator");

          // Submit data
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("folioItem", values["folioItem"]);
            formData.append(
              "certificate",
              values["certificate"] as string | Blob
            );
            // new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${values["route"]}/${values["folioItem"]}/certificate`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                // resetForm();
                // Re-route to policy certificate upload page
                router.push({
                  name: props?.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
            // name: "underwriter-dashboard",
          }
        }, 2000);
      }
    };
    return { documentUploadValidator, submitForm };
  },
  created() {
    this.submitRoute =
      this.role == variables.UNDERWRITER_USER_ROLE ? "underwriter" : "policy";
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    documentSelected(event) {
      this.certificate = event.target.files[0];
    },
  },
});
